#landing-page-banner{
  position: relative;
  overflow: hidden;
  background: #ececec;
  margin-bottom: 20px;

  .landing-text{
    position: relative;
    max-width: 750px;
    margin: 0 auto;
    float: none;
    text-align: center;
    padding: 50px 0;
  }

  .left,.right{
    position: absolute;
    z-index: 0;
    top: 0;
  }
  .left{ left: 0; }
  .right{ right: 0; }

  @include max-screen($screen__m) {
    .right,.left{ display: none }
  }
}

#show-by-category{
  padding: 50px 0;

  & > h3{
    text-align: center;
    margin-bottom: 40px;
  }

  .sub-category{
    text-align: center;
    background: #ececec;
    margin-bottom: 30px;

    h4{
      font-size: 21px;


      strong{
        display: block;
        width: 100%;

      }
    }
    /*icon*/
    & > span{
      padding: 5px 0;
      display: block;
    }
    & > div{
      padding: 30px 0;
      background: white;
    }
  }
}

#popular-breeds{
  margin-bottom: 40px;

  & > h3{
    text-align: center;
    position: relative;

    span{
      display: inline-block;
      padding: 0 20px;
      background: #fff;
    }
    &:after{
      content: ' ';
      display: block;
      height: 1px;
      background: #dcdad3;
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      z-index: -1;
    }
  }

  .slick-slide{
    a{
      display: block;
      text-align: center;

      img{
        display: inline-block;
      }
    }
  }
}

#landing-featured-slider{
  margin-bottom: 40px;

  & > h3{
    text-align: center;
    position: relative;

    span{
      display: inline-block;
      padding: 0 20px;
      background: #fff;
    }
    &:after{
      content: ' ';
      display: block;
      height: 1px;
      background: #dcdad3;
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      z-index: -1;
    }
  }

}