//
// Pager
// --------------------------------------------------

.pager {
    text-align: center;
}

.pages {
    @include lib-pager();
    line-height: 32px;

    .action {
        border: 0;
    }
}
