//
//  Custom Styles
//  ---------------------------------------------

.modal-popup {
    pointer-events: none;
}

@mixin overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;

  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  > * {
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
  }

  &.row,
  &.clearfix {
    &:before {
      content: none;
    }
  }

  &.flex-column {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  &.flex-nowrap {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  &.flex-fullwidth > * {
    -webkit-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
  }

  img {
    flex-shrink: 0;
  }
}

.iframe-responsive {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-bottom: 56.25%;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
}

.cms-page-view {
    .column.main {
        p {
            @include lib-font-size(15);
        }
        ul {
            @include lib-font-size(15);
            padding-left: $indent__base;
        }
    }
}
