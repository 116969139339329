.breadcrumbs {
    @include lib-breadcrumbs();
    margin: $indent__m 0;

    .item {
        &:after {
            content: ">";
            margin: 0 $indent__xs;
            color: #b0b0b0;
        }
        &:last-child {
            &:after {
                display: none;
            }
        }
    }
}
