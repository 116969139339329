//
//  Toolbar
//  ---------------------------------------------

.page-products {
    .columns {
        position: relative;
    }
}

.toolbar-products {
    @extend .abs-add-clearfix;
    margin-bottom: $indent__l;
    text-align: center;
}

//
//  Amount
//  ---------------------------------------------

.toolbar-amount {
    padding-right: 20px !important;
    margin-right: 10px;
    font-size: 14px;
    float: left;
    line-height: 39px !important;
    margin-bottom: 0;
    color: #6d6d6d;
    position: relative;

    &:after{
        content: " ";
        width:1px;
        height:15px;
        background:#dcdcdc;
        position: absolute;
        right:0;
        top: 14px;
        display:block;
    }
}

//
//  Sorter
//  ---------------------------------------------

.sorter {
    float: left;

    .products.wrapper ~ .toolbar & {
        display: none;
    }
    .sorter-label {
        font-size: 14px;
        float: left;
        line-height: 39px;
        margin-bottom: 0;
        color: #6d6d6d;
        position: relative;

        &:after {
            content: ":";
        }
    }
    .sorter-options {
        width: auto;
        border-color: #ccc;
        margin: 0 5px;
        padding: 0 20px 0 10px;
        height: 36px;
        color: #6d6d6d;
    }
    .sorter-action {
        color: $text__color;
        display:none !important;

        span {
            @extend .abs-visually-hidden;
        }
        &.sort-asc {
            &:before {
                @include fas();
                @include fa-icon();
                content: fa-content($fa-var-arrow-up);
            }
        }
        &.sort-desc {
            &:before {
                @include fas();
                @include fa-icon();
                content: fa-content($fa-var-arrow-down);
            }
        }
    }
}

//
//  Modes
//  ---------------------------------------------

.modes {
    float: right;

    @include max-screen($screen__l) {
        display: none;
    }

    .products.wrapper ~ .toolbar & {
        display: none;
    }
    .modes-label {
        @extend .abs-visually-hidden-desktop;
    }
    .modes-mode {
        color: $primary__color;
        border: 1px solid #d9ddde;
        float: left;
        padding: $indent__s;
        margin-left: $indent__s;
        font-weight: normal;
        border-radius: 1px;

        &:before {
            @include fas();
            @include fa-icon();
            @include lib-font-size(18px);
            content: fa-content($fa-var-th-large);
            margin-right: $indent__xs;
            position: relative;
            top: 1px;
        }
        &.mode-list {
            &:before {
                content: fa-content($fa-var-th-list);
            }
        }
        &:hover,
        &.active {
            text-decoration: none;
            border-color: #c4c9cd;
        }
    }
}

//
//  Limiter
//  ---------------------------------------------

.limiter {
    float: right;

    @include max-screen($screen__m) {
        display: none;
    }

    .products.wrapper ~ .toolbar & {
        display: none;
    }
    .label,
    .limiter-text {
        @include lib-font-size(15px);
    }
    .control {
        display: inline-block;

        .limiter-options {
            border-color: #c4c9cd;
            width: auto;
            padding: 0 10px;
            line-height: 37px;
            margin: 0 5px;
            height: 37px;
        }
    }
}

//
//  Pages
//  ---------------------------------------------
.pages{
    display: none;
}
.toolbar-bottom{
    .toolbar-amount,
    .toolbar-sorter.sorter,
    .field.limiter{ display: none; }
    .pages{
        display: inline-block;
    }
}
