//
//  Methods
//  --------------------------------------------

.amcheckout-shipping-methods {
    .row.amcheckout-method {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
    }
    .col.col-checkbox,
    .col.col-price,
    .col.col-method {
        padding: 15px;
    }
    .col-method {
        flex-grow: 2;
    }
    .col.col-comment {
        width: 100%;
        padding: 20px 0 30px;
        color: #444;
    }
}