//
//  Contact
//  ---------------------------------------------

.form.contact {
    margin-bottom: 30px;

    .fieldset {
        .legend {
            display: none;
        }
        .field.note {
            display: none;
        }
    }

    .action.submit.primary{
        width: 100%;
    }

    .required-captcha.checkbox{
        display: none;
    }
}
#contact-address{
    address{
        margin-bottom: 15px;
    }
    address,
    p{
        font-size:16px;
    }
}
.contact-map{
    iframe {
        width: 100%;
        border: 0;
        height: 300px;
    }
}