//Colour variations
$blue__color                                    : #9bced2;
$blue-light__color                              : #9bced2;

$yellow__color                                  : #ebbb42;
$yellow-light__color                            : #ebbb42;

$green-dark__color                             : #3e3d30;
$green__color                                   : #525e2b;
$green-light__color                             : #73a154;

$red__color                                     : #d76666;
$red-light__color                               : #d76666;

$pink__color                                    : #f39b9b;
$pink-light__color                              : #f39b9b;

$orange__color                                  : #e59471;
$orange-light__color                            : #e59471;

$purple__color                                  : #8966d7;
$purple-light__color                            : #8966d7;

$gray-darker__color                             : #353535;
$gray-dark__color                               : #494949;
$gray__color                                    : #6c6c6c;
$gray-lightest__color                           : #ececec;

$black__color                                    : #000;

$white__color                                    : #fff;

//
//  Color variables
//  _____________________________________________

$primary__color                                 : #1b215c; // Salmon
$secondary__color                               : #eaf7fc; // yellow
$special__color                                 : #d33c3c; // red
$border__color                                  : #ccc;



$active__color                                  : $primary__color;
$active-light__color                            : #c1dc8f;


