.well{
  background:#fbfbfb;
  padding: 30px;
  margin-bottom: 30px;
}

.grey-box{
  background:#fbfbfb;
  padding: 30px;
  margin-bottom: 30px;
}

.pink-box{
  background:#fee5e8;
  padding: 30px;
  margin-bottom: 30px;

  *{
    color:#da7a85;
  }
}

.light-blue-box{
  background: #eaf7fc;
  padding: 30px;
  margin-bottom: 30px;

  *{
    color:#1b215c;
  }
}

.dark-blue-box{
  background-color: #1b215c;
  padding: 30px;
  margin-bottom: 30px;

  *{
    color:#fff;
  }
}
