#checkout-loader{
  display:none;
}

.amcheckout-step-container > li {
  list-style: none;
}

.step-title,
.step-title.amcheckout-title{
  display: block;
  margin: 0;
  padding: 15px;
  background: #eee;
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
}

.payment-method{
  display: block;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 20px;
  width: 100%;
  border: 1px solid #f9f9f9;
  border-radius: 2px;
  background: #f9f9f9;
  cursor: pointer;
}

.opc-block-summary .step-content.amcheckout-content{
  background: #f9f9f9;
  cursor: default;
}
.opc-block-summary .step-content.amcheckout-content{
  padding: 20px;
}

.checkout-index-index .opc-block-summary .minicart-items .product-image-container{
  margin-left: 0 !important;
}
.checkout-index-index .opc-block-summary .minicart-items .delete{
 display:none !important;
}

.checkout-index-index .opc-block-summary .minicart-items .product-item-details {
  padding-left: 60px !important;
}
.opc-block-summary .product-item .product-item-name {
  text-align: left;
}

.checkout-payment-method .payment-methods .actions-toolbar .primary {
  font-size: 1.8rem;
  line-height: 2.2rem;
  padding: 14px 17px;
  width: 100%;
}
@include min-screen($screen__l) {
  .amcheckout-main-container.-modern.-layout-3columns.am-submit-summary{
    display: flex;
    justify-content: space-between;
  }

  .amcheckout-main-container.-modern.-layout-3columns.am-submit-summary .amcheckout-column{
    width: 32%;
  }
}

