//Main Homepage Banner
#mobile-main-banner,
#main-banner {
  margin-bottom: 30px;
  position: relative;

  .main-slider-arrows{
    position: absolute;
    top: 50%;
    width: 100%;
    z-index: 1;

    .container{
      position: relative;


      .slick-arrow{
        width:43px;
        height:47px;
        background: #00000036;
        font-size: 16px;
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        padding: 0;

        &.slick-prev:before {
          content: "\f053";
        }
        &.slick-next:before {
          content: "\f054";
        }
      }
    }
  }
  .slick-slide{
    text-align: center;
  }
}
#mobile-main-banner{
  display:none;
  text-align: center;
}
@include max-screen($screen__m) {
  #main-banner{
    display:none;
  }
  #mobile-main-banner{
    display: block;
  }
}


//USP bar
#usp-bar {
  border-top: 1px solid #dcdad3;
  border-bottom: 1px solid #dcdad3;
  padding: 15px 0;

  @include min-screen($screen__l) {
    padding: 15px 50px;
  }
  a{
    padding-left: 55px;
    position: relative;
    display: block;
    font-size: 19px;
    color: $green-dark__color;
    font-weight: bold;
    line-height: 19px;

    &:hover{
      text-decoration: none;
    }

    img{
      position: absolute;
      left: 0;
      top: 0;
    }

    span{
      color: $secondary__color;
      font-size: 13px;
    }
  }
}

//Quick Tabs
#quick-tabs{
  text-align: center;
  margin-bottom: 30px;

  @include lib-clearfix();

  h3{
    font-size: 26px;
    color: $primary__color;
    font-family: "Domine", serif;
  }
  p{
    color: #6b6b6b;
  }

  .action.primary{
    margin: 20px auto;
    display: block;
    width: 160px;
    text-align: center;
  }
}

//Our story
#our-story{
  margin-bottom: 70px;

  @include lib-clearfix();

  &  > div{
    padding: 0px;

    &:first-child{
      height: 360px;
    }
    .well {
      padding: 40px 50px;
      background-color: #ebf2fa;
      -webkit-box-shadow: none;
      box-shadow: none;
      border-radius: 0px;
      height: 360px;

      h3 {
        color: #1b215c;
        margin-bottom: 20px;
        font-size: 30px;
        font-weight: 500;
        font-family: "Domine", serif;
      }
      p{
        color: #696d70;
      }
      .action.primary{
        margin-top: 20px;
        padding: 10px 30px;
      }
    }
  }
}

//Featurd items
#homepage-featured{
  h3 {
    font-size: 22px;
    color: #1b215c;
    margin-bottom: 30px;
    font-weight: 600;
    text-align: center;
    line-height: 30px;
    font-family: "Domine", serif;

    span {
      color: $gray__color;
      font-family: $font-family-name__base;
      font-size: 16px;
      display: block;
      font-weight: 400;
    }
  }
}

//USP bar
#usp-bar {
    border-top: 1px solid #dcdad3;
    border-bottom: 1px solid #dcdad3;
    padding: 15px 0;

    @include min-screen($screen__l) {
      padding: 15px 50px;
    }
    a{
      padding-left: 55px;
      position: relative;
      display: block;
      font-size: 19px;
      color: $green-dark__color;
      font-weight: bold;
      line-height: 19px;

      &:hover{
        text-decoration: none;
      }

      img{
        position: absolute;
        left: 0;
        top: 0;
      }

      span{
        color: $secondary__color;
        font-size: 13px;
      }
    }
  }

//Quick Tabs
#quick-tabs{
    text-align: center;
    margin-bottom: 30px;

    @include lib-clearfix();

    h3{
      font-size: 30px;
      color: $primary__color;
    }
    p{
      color: #6b6b6b;
    }

    .action.primary{
      margin: 20px auto;
      display: block;
      width: 160px;
      text-align: center;
    }
  }

//Our story
#our-story{
    margin-bottom: 70px;

    @include lib-clearfix();

    &  > div{
      padding: 0px;

      &:first-child{
        height: 360px;
      }
      .well {
        padding: 40px 50px;
        background-color: #ebf2fa;
        -webkit-box-shadow: none;
        box-shadow: none;
        border-radius: 0px;
        height: 360px;

        h3 {
          color: #1b215c;
          margin-bottom: 20px;
          font-size: 30px;
          font-weight: 500;
        }
        p{
          color: #696d70;
        }
        .action.primary{
          margin-top: 20px;
        }
      }
    }
  }

//Featurd items
#homepage-featured{
    h3 {
      font-size: 22px;
      color: #1b215c;
      margin-bottom: 30px;
      font-weight: 600;
      text-align: center;
      line-height: 30px;

      span {
        color: $gray__color;
        font-family: $font-family-name__base;
        font-size: 16px;
        display: block;
        font-weight: 400;
      }
    }
  }

#seasonal-banner,
#new-banner{
    padding: 20px;
    background: #cceaea;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 36px;
    height: 300px;

    h4{
      font-family: 'langdon';
      font-size: 46px;
      color: $primary__color;
      font-weight: 500;
      text-transform: lowercase;
    }

    p{
      color:#6d6d6d;
    }

    .action.primary{
      padding:10px 30px;
    }
  }
//Sesonal banner
#seasonal-banner{
 background: #feefc3;
}

//New banner
#new-banner{
  background: #cceaea;
}

//New banner
#new-banner{
  background: #cceaea;
}

#homepage-featured{
  margin-bottom: 50px;
}

//Reviews slider
#homepage-reviews{
  margin-bottom:50px;

  h3{
    text-align: center;
    color: $primary__color;
    font-size: 22px;
    margin-bottom:30px;
    font-family: "Domine", serif;
  }
  #reviews-slider{
    .slick-slider{
      padding: 0 50px;
    }

    .border{
      padding: 30px 10px;
      text-align: center;
      border:1px solid #e8e8e8;

      p{
        color: $primary__color;
        font-size: 16px;

        &.review{
          font-weight: 600;
        }
        &.date{
          color: #8a8b97;
          font-size: 14px;
        }
      }
    }
  }
}

.stars {
  background: url(../Magento_Cms/images/stars.jpg);
  height: 14px;
  display: inline-block;

  &.stars-1{width: 17px;}
  &.stars-2{width: 34px;}
  &.stars-3{width: 52px;}
  &.stars-4{width: 70px;}
  &.stars-5{ width: 87px; }
}




