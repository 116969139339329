//Padding Spacing
@for $i from 1 through 20 {
  .p-#{$i * 5}{
    padding: #{$i * 5}px;
  }
  .pr-#{$i * 5}{
    padding-right: #{$i * 5}px;
  }
  .pl-#{$i * 5}{
    padding-left: #{$i * 5}px;
  }
  .pt-#{$i * 5}{
    padding-top: #{$i * 5}px;
  }
  .pb-#{$i * 5}{
    padding-bottom: #{$i * 5}px;
  }
  .px-#{$i * 5}{
    padding-left: #{$i * 5}px;
    padding-right: #{$i * 5}px;
  }
  .py-#{$i * 5}{
    padding-top: #{$i * 5}px;
    padding-bottom: #{$i * 5}px;
  }
}
//Margin spacing
@for $i from 1 through 20 {
  .m-#{$i * 5}{
    margin: #{$i * 5}px;
  }
  .mr-#{$i * 5}{
    margin-right: #{$i * 5}px;
  }
  .ml-#{$i * 5}{
    margin-left: #{$i * 5}px;
  }
  .mt-#{$i * 5}{
    margin-top: #{$i * 5}px;
  }
  .mb-#{$i * 5}{
    margin-bottom: #{$i * 5}px;
  }
  .mx-#{$i * 5}{
    margin-left: #{$i * 5}px;
    margin-right: #{$i * 5}px;
  }
  .my-#{$i * 5}{
    margin-top: #{$i * 5}px;
    margin-bottom: #{$i * 5}px;
  }
}

