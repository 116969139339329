//
//  Product page
//  _____________________________________________


//
//  Product page layout
//  ---------------------------------------------

.row {
  &.primary {
    margin-bottom: $indent__xxl;
    padding: 0 100px;

    @include max-screen($screen__xl) {
      margin-bottom: $indent__xl;
    }
    @include max-screen($screen__l) {
      margin-bottom: $indent__l;
      padding: 0 0;
    }
    @include max-screen($screen__m) {
      margin-bottom: $indent__base;
    }

    .product.media {
      @include make-xxs-column(12);
      @include make-m-column(7);
    }
    .product-info-main {
      @include make-xxs-column(12);
      @include make-m-column(5);
    }
  }
}

//
//  Product media
//  ---------------------------------------------

.gallery-placeholder:not(._block-content-loading) .gallery-placeholder__image {
  display: none;
}

.product-item-details {
  .product-image-container {
    display: block;
    max-width: 100%;
  }
  .product-image-wrapper {
    display: block;
    height: 0;
    overflow: hidden;
    position: relative;
    z-index: 1;
  }
  .product-image-photo {
    bottom: 0;
    display: block;
    height: auto;
    left: 0;
    margin: auto;
    max-width: 100%;
    position: absolute;
    right: 0;
    top: 0;
  }
}

//
//  Product media
//  ---------------------------------------------

.product.media {
  position: relative;

  .product-item-brand {
    position: absolute;
    left: $indent__m;
    top: 0;
    z-index: 19;
  }
  .fotorama {
    position: relative;
  }
  .fotorama__img {
    cursor: zoom-in;
  }
  .fotorama__stage {
    box-sizing: border-box;

    &:after {
      @include fa-icon();
      @include fas();
      content: fa-content($fa-var-search);
      position: absolute;
      bottom: $indent__m;
      right: $indent__m;
      color: #999999;
      @include lib-font-size(24);
    }
  }

  .fotorama__caption {
    display: none;
  }
  .fotorama__thumb {
    box-sizing: border-box;
    border: 1px solid #eeeff0;
  }
  .product.photo .photo.image {
    @extend .abs-adaptive-images-centered;
  }
  .placeholder .photo.container {
    max-width: 100%;
  }
  .notice {
    @include lib-css(color, $text__color__muted);
    @include lib-font-size($font-size__s);
    margin: $indent__s 0;
  }
  .product.thumbs {
    margin: $indent__base 0 $indent__l;
  }
  .items.thumbs {
    @include lib-list-inline();

    .active {
      display: block;
      line-height: 1;
    }
  }
}

//
//  Product clean
//  ---------------------------------------------

.product-info-clean {
  h2 {
    text-align: center;
    @include lib-font-size(28);
    margin: $indent__xl 0;

    @include max-screen($screen__xl) {
      margin: $indent__l 0;
    }
    @include max-screen($screen__l) {
      margin: $indent__base 0;
    }

    strong {
      color: $secondary__color;
    }
  }
  .more {
    text-align: center;

    .fas {
      margin-left: $indent__xs;
    }
  }
  .col-l-2 {
    @include min-screen($screen__l) {
      width: 20%;
    }
  }
  .row {
    @include max-screen($screen__xs) {
      margin-right: 0;
    }
  }
  .col {
    @include max-screen($screen__xs) {
      padding-right: 0;
    }
  }
  .image {
    text-align: center;
    line-height: 1.1;
    @include lib-font-size(16);
    margin-bottom: $indent__xl;

    @include max-screen($screen__xl) {
      margin-bottom: $indent__l;
    }
    @include max-screen($screen__l) {
      margin-bottom: $indent__base;
    }
    @include max-screen($screen__xs) {
      @include lib-font-size(14);
    }

    img {
      display: inline-block;
      margin-bottom: $indent__s;
    }
    span {
      height: 34px;
      display: block;
    }
  }
}

//
//  Product main
//  ---------------------------------------------

.product-info-main {

  //Alert
  &.alert {
    margin: $indent__s 0;
  }

  //Coupon
  .alert.alert-coupon{
    @include lib-icon-font(
            $_icon-font-content     : $icon-present,
            $_icon-font-color       : #da8195,
            $_icon-font-size        : 30px,
            $_icon-font-line-height : 30px,
            $_icon-font-display     : block,
            $_icon-font-text-hide   : true
    );

    display: flex;
    text-decoration: none;
    border: 1px solid #da8195;
    border-radius: 5px;
    background: #fee5e8;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    color: #da8195;
    align-items: center;
  }

  //Poduct Title
  .page-title-wrapper {
    .page-title {
      font-family: "Domine", serif;
      @include lib-font-size(28px);
      font-weight: 600;
      margin: 0 0 $indent__s;
      line-height: 32px;

      @include max-screen($screen__m) {
        margin-top: $indent__base;
      }
    }
  }

  //Brand


  //Sku


  //Product Price
  margin-bottom: $indent__base;


  .product.attribute.short-description {
    margin-bottom: $indent__l;

    .type {
      display: none;
    }
    .value {
      @include lib-font-size(15px);
    }
  }


  //Price
  .price-box {

    //Old Price
    .old-price {
      @include lib-font-size(23px);

      .price-wrapper {
        .price {
          @include lib-font-size(23px);
          color: $green-dark__color;
        }
      }
    }

    //Normal Price
    &.price-final_price,
    .normal-price {
      .price-wrapper {
        .price {
          @include lib-font-size(30px);
          color: $primary__color;
        }
      }
    }

    //Special Price
    .special-price {
      .price-wrapper {
        .price {
          @include lib-font-size(23px);
          color: $special__color;
        }
      }
    }
  }


  //Sku / stock price
  .product-info-stock-sku{
    padding-top: 20px;
    border-top: 1px solid #ececec;
    margin: 20px 0;

    .stock{
      &.unavailable{
        i{
          color: #b54b4b;
        }
      }
      &.available{
        i{
          color:#80b54b;
        }
      }
    }
    span{
      font-size:15px;
      color: #6d6d6d;
    }
  }
  //Add to Form
  .product-add-form {
    @include lib-clearfix();
    margin-bottom: 54px;
    position: relative;

    .product-options-wrapper {
      margin-bottom: $indent__l;

      .fieldset {
        & > .field:not(.configurable):not(.choice) {
          margin-bottom: $indent__s;

          & > .label {
            width: auto;
            padding: 4px 0;
          }
          & > #value {
            display: none;
          }
          & > .control {
            width: auto;
            float: right;

            .swatch-attribute-options {
              margin: 0 -5px 0 0;
            }
            .choice {
              @include lib-clearfix();

              input {
                display: none;

                &[type="checkbox"] {
                  float: left;
                  display: block;
                  visibility: hidden;
                }
                &:checked {
                  & + .label {
                    &:before {
                      content: fa-content($fa-var-check-square);
                      color: $primary__color;
                    }
                    &.loading {
                      &:before {
                        @extend .fa-spin;
                        font-weight: 900;
                        content: fa-content($fa-var-spinner);
                      }
                    }
                  }
                }
              }
              .label {
                float: right;
                cursor: pointer;

                &:before {
                  @include fa-icon();
                  @include far();
                  content: fa-content($fa-var-square);
                  color: #c4c9cd;
                }
                &.loading {
                  &:before {
                    @extend .fa-spin;
                    font-weight: 900;
                    content: fa-content($fa-var-spinner);
                  }
                }
              }
            }
          }
          &:last-child {
            margin-bottom: $indent__m;
          }
        }
      }
    }


    .box-tocart {
      .fieldset {
        @include lib-clearfix();
        margin-right: 0;

        & > .field {
          text-align: left;
        }
      }
      .field.qty {
        .label {
          position: absolute;
          left: $indent__m;
          top: 0;
          line-height: 44px;
          padding: 0;
          width: 75px;
          @include lib-font-size(15px);
          font-weight: normal;
        }
        .control {
          position: relative;
          display: flex;
          width: auto;
          gap: 4px;
        }
        .qty {
          display: block;
          text-align: center;
          height: 40px;
          width: 40px;
          line-height: 40px;
          @include lib-font-size(15px);
        }
        .btn-qty-decrease,
        .btn-qty-increase {
          background-color: #f9f9f9;
          @include lib-css(transition, all 0.1s);
          line-height: 40px;
          height: 40px;
          width: 40px;
          text-align: center;
          color: #cecece;
          padding: 0;
          position: relative;

          &:hover {
            background-color: $primary__color;

            &:after,
            &:before {
              color:#fff;
            }
          }

          &:before{
            width: 12px;
            height: 2px;
            left: 13px;
          }

          &:after,
          &:before{
            content:' ';
            display: block;
            background-color:#6f6f6f;
            position: absolute;
          }
        }

        .btn-qty-increase{
          &:after{
            height: 12px;
            width: 2px;
            left: 18px;
            top: 14px
          }
        }
      }

      .paypal.checkout {
        @include make-xxs-column(12);
        text-align: center;
      }

      .actions {
        position: relative;
        display:flex;

        .label {
          display: none;
        }
        .action.tocart {
          text-transform: uppercase;
          @include lib-font-size(17px);
          flex: 1 1 100%;
        }

        //Wish List
        .action.towishlist {
          padding: 0 20px;

          span {
           color: #6d6d6d;
            font-size: 14px;
          }
        }
      }
      .product-social-links {
        @include make-xxs-column(2);
        text-align: center;

      }
      .prices-tier {
        @include make-xxs-column(12);
        margin-bottom: $indent__base;

        & > li {
          padding: $indent__xs $indent__s;

          &:nth-of-type(2n+1) {
            background-color: #e7e8ea;
          }
          .price-container {
            display: none;
          }
          strong {
            float: right;
            color: #f13131;
            font-weight: normal;
          }
        }
      }
    }
  }
}

.description-area{
  padding: 50px 0;

  .title{
    @include lib-font-size(21);
    border-bottom: 1px solid #cdcdcd;
    padding-bottom: 10px;
    margin-bottom: 30px;
  }

  table{

    tr:nth-child(odd){
      background: #e5e4e4;
    }
  }
}

//
//  Product reviews
//  ---------------------------------------------

.product-info-reviews {
  @include max-screen($screen__l) {
    padding-left: $indent__xxl;
  }
  @include max-screen($screen__m) {
    padding-left: 0;
    text-align: center;

  }

  h2 {
    @include lib-font-size(20);
    text-transform: uppercase;
    font-family: "Black Han Sans", Arial, sans-serif;
    letter-spacing: 0.04em;
    color: $gray__color;
    margin-top: $indent__m;
    font-weight: 400;
  }
}

//
//  Product related / upsell
//  ---------------------------------------------

.related-wrapper{
  background-color: #ececec;
  padding: 70px 0;
}

.block.related,
.block.upsell {

  .block-title.title {
    margin: $indent__base 0 50px;

    @include max-screen($screen__xl) {
      margin: $indent__s 0 $indent__base;
    }
    @include max-screen($screen__l) {
      margin: 0 0 $indent__s;
    }

    strong {
      @include lib-font-size(22px);
      color: $primary__color;
    }
  }
  .block-content {
    .block-actions {

    }
    .field.choice.related {

    }
  }
}

.products.wrapper.grid.products-grid.products-related{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  max-height: 300px;
  overflow: auto;

  .product-items {
    gap: 10px;
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .product-item{
      width: 30%;
      float: left;

      @include max-screen($screen__s) {
        width:48%;
      }
      .product-item-link{
        font-size: 14px;
      }
      .price-box {
        padding-bottom: 20px;
        text-align: left;
        color: #1b215c;
      }
      .price-box .normal-price{
        .price {
          font-size: 14px;
          color: #1b215c;
          padding: 0;
          text-align: left;
        }
      }

    }
  }
}


.block.upsell{
  border-top:1px solid #e8e8e8;
  padding-top:30px;

  .block-title.title{
    font-family: "Domine", serif;
    font-size:22px;
  }
  .slick-list {
    margin:0;
  }
  .slick-slider{
    padding: 0 47px;
  }
  .slick-track{
    margin-left: 0;
  }
  .slick-slide{
    padding: 0 10px;
  }
}
//
//  Product info tabs
//  ---------------------------------------------

.product.info.detailed {
  clear: both;
  margin-bottom: 30px;

  //Tab
  .data.item.title{
    margin: 0;
    border-bottom: 1px solid #ddd;
    height: 40px;

    .data.switch{
      font-size: 12px;
      color: #6d6d6d;
      border: 1px solid #ddd;
      padding-top: 9px;
      margin-right: 4px;
      padding-bottom: 9px;
    }
    &.active{
      & > .switch:after {
        content: "";
        width: 100%;
        height: 4px;
        background-color: #ffffff;
        position: absolute;
        top: 98%;
        left: 0;
        margin-left: 0;
      }
    }
  }
  .additional-attributes {
    width: auto;

    tr{
      padding: 5px 10px;

      &:nth-of-type(odd) {
        background: #0000000a;
      }
      th{
        padding: 4px 10px 10px 10px !important;
      }
      td{
        padding: 0 0 0;
      }
    }

    @include lib-table-resize(
            $_th-padding-left   : 0,
            $_th-padding-right  : $indent__l,
            $_th-padding-bottom : $indent__s,
            $_td-padding-bottom : $indent__s
    );
  }

  .data.item.content {
    background: #ffffff;
    margin-top: 45px;
    padding: 20px 20px 30px 20px;
    border: 0px solid #ccc;
  }
  .product.attribute.description {
    @include lib-font-size(15);

    ul {
      padding-left: $indent__base;
    }
    table {
      width: auto !important;

    }
    .css_t1 {
      font-weight: bold;
      padding-left: 0;
      padding-right: 0;
    }
    .css_t2 {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .additional-attributes-wrapper {
    margin: 0;
  }
  .additional-attributes {
    @include lib-table-resize(
            $_th-padding-left   : 0,
            $_th-padding-right  : $indent__s,
            $_th-padding-bottom : $indent__s,
            $_td-padding-bottom : $indent__s
    );

    .label {
      &:after {
        content: ":";
      }
    }
  }
}


//Product share links
#share-links{
  display: flex;
  align-items: center;
  gap:10px;

  span{
    display: inline-block;
    font-size: 14px;
    color: #6d6d6d;
    padding-right: 20px;
  }
  a{
    display: inline-block;
    color: #6d6d6d;
    font-size: 20px;
    line-height: 20px;
    padding: 10px;
  }
}
//
//  Added to cart popup
//  ---------------------------------------------

.add-to-cart-popup {
  .modal-inner-wrap {
    background-color: $gray-lightest__color;
    max-width: 560px;

    .modal-header,
    .modal-content,
    .modal-footer {
      padding: 3rem;
    }
    .modal-footer {
      padding-top: 0;
      font-size: 0;
      text-align: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      .action {
        margin-bottom: 6px;
        margin-left: 3px;
        width: 49%;
        flex-grow: 1;
        display: flex;
        text-align: center;
        justify-content: center;
      }
    }
  }
}
