//
//  Variables
//  _____________________________________________

$active-nav-indent: 54px;
$navigation__background: $color-white;
$navigation-desktop__background: $navigation__background;

.nav.wrapper {
    position: relative;
}

.nav-sections {
    @include lib-css(background, $navigation__background);
}

.nav-toggle {
    @include lib-icon-text-hide();
    cursor: pointer;
    display: block;
    float: left;
    position: absolute;
    top: 25px;
    left: 15px;
    margin-top: 0px;
    z-index: 1;

    &:before {
        @extend .fas;
        @include fa-icon();
        @include lib-font-size(25px);
        content: fa-content($fa-var-bars);
        color: $green__color;
    }



}

.nav.header {
    display: inline-block;
    @extend .abs-add-clearfix;

    @include max-screen($screen__l) {
        display: none;
    }

    & > ul {
        @extend .abs-reset-list;
        float: left;

        & > li {
            float: left;
            margin-left: $indent__l;

            @include max-screen($screen__xl) {
                margin-left: $indent__xs;
            }

            & > a {
                @include lib-font-size(17px);
                display: block;
                color: $text__color;
                font-weight: bold;
                padding: $indent__l $indent__xs;
                border-left: $indent__xs solid $color-white;
                border-right: $indent__xs solid $color-white;

                @include max-screen($screen__xl) {
                    padding: $indent__l 0;
                }

                &:hover {
                    text-decoration: none;
                    padding-bottom: ($indent__l - $indent__xs);
                    border-bottom: $indent__xs solid $primary__color;
                }
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__l) {

    .topmegamenu {
        display: none;
    }

    .navigation {
        padding: 0;

        .level0 {
            > .level1,
            > .all-category {
                a {
                    font-weight: bold;
                }
            }
        }

        .parent {
             .level-top {
                position: relative;
                font-weight: bold !important;

                @include lib-icon-font(
                    $_icon-font-content  : $icon-down,
                    $_icon-font-size     : 42px,
                    $_icon-font-position : after,
                    $_icon-font-display  : block
                );

                &:after {
                    position: absolute;
                    right: 7px;
                    top: -8px;
                }

                &.ui-state-active {
                    @include lib-icon-font-symbol(
                        $_icon-font-content  : $icon-up,
                        $_icon-font-position : after
                    );
                }
            }
        }
    }

    .nav-sections {
        -webkit-overflow-scrolling: touch;
        @include lib-css(transition, left 0.3s, 1);
        height: 100%;
        left: -80%;
        left: calc(-1 * (100% - #{$active-nav-indent}));
        overflow: auto;
        position: fixed;
        top: 0;
        width: 80%;
        width: calc(100% - #{$active-nav-indent});

        .switcher {
            border-top: 1px solid $color-gray82;
            font-size: 1.6rem;
            font-weight: 700;
            margin: 0;
            padding: 0.8rem 3.5rem 0.8rem 2rem;

            .label {
                display: block;
                margin-bottom: $indent__xs;
            }

            &:last-child {
                border-bottom: 1px solid $color-gray82;
            }
        }

        .switcher-trigger {
            strong {
                position: relative;
                @include lib-icon-font(
                    $_icon-font-content  : $icon-down,
                    $_icon-font-size     : 42px,
                    $_icon-font-position : after,
                    $_icon-font-display  : block
                );

                &:after {
                    position: absolute;
                    right: -40px;
                    top: -16px;
                }
            }

            &.active strong {
                @include lib-icon-font-symbol(
                    $_icon-font-content  : $icon-up,
                    $_icon-font-position : after
                );
            }
        }

        .switcher-dropdown {
            @include lib-list-reset-styles();
            padding: $indent__s 0;
        }

        .header.links {
            @include lib-list-reset-styles();
            border-bottom: 1px solid $color-gray82;

            li {
                font-size: 1.6rem;
                margin: 0;

                &.greet.welcome {
                    border-top: 1px solid $color-gray82;
                    font-weight: 700;
                    padding: 0.8rem $indent__base;
                }

                > a {
                    border-top: 1px solid $color-gray82;
                    text-transform: uppercase;
                }
            }

            a,
            a:hover {
                @include lib-css(color, $navigation-level0-item__color);
                @include lib-css(text-decoration, $navigation-level0-item__text-decoration);
                display: block;
                font-weight: 700;
                padding: 0.8rem $indent__base;
            }

            .header.links {
                border: 0;
            }
        }
    }

    .nav-before-open {
        height: 100%;
        overflow-x: hidden;
        width: 100%;

        .page-wrapper {
            @include lib-css(transition, left 0.3s, 1);
            height: 100%;
            left: 0;
            position: relative;
        }

        body {
            height: 100%;
            overflow: hidden;
            position: relative;
            width: 100%;
        }
    }

    .nav-open {
        .page-wrapper {
            left: 80%;
            left: calc(100% - #{$active-nav-indent});
        }

        .nav-sections {
            $_shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);

            @include lib-css(box-shadow, $_shadow, 1);
            left: 0;
            z-index: 99;
        }

        .nav-toggle {
            &:after {
                background: rgba(0, 0, 0, $overlay__opacity);
                content: '';
                display: block;
                height: 100%;
                position: fixed;
                left: 0;
                top: 0;
                width: 100%;
                z-index: 1;
            }
        }
    }

    .nav-sections-items {
        @include lib-clearfix();
        position: relative;
        z-index: 1;
    }

    .nav-sections-item-title {
        background: darken($navigation__background, 5%);
        border: solid darken($navigation__background, 10%);
        border-width: 0 0 1px 1px;
        box-sizing: border-box;
        float: left;
        height: 71px;
        padding-top: 27px;
        text-align: center;
        width: 33%;

        a {
            @include lib-font-size(14px);
            text-transform: uppercase;
            color: $green__color;
        }

        &.active {
            background: transparent;
            border-bottom: 0;
        }

        .nav-sections-item-switch {
            &:hover {
                text-decoration: none;
            }
        }
    }

    .nav-sections-item-content {
        @include lib-clearfix();
        box-sizing: border-box;
        float: right;
        margin-left: -100%;
        margin-top: 71px;
        width: 100%;

        &.active {
            display: block;
        }

        padding: $indent__m 0;
    }

    @include lib-main-navigation();
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__l) {
    @include lib-main-navigation-desktop();
    .page-menuheader {
        background-color: $color-white;
        position: relative;
        z-index: 30;
        border-top: 1px solid $gray-lightest__color;
        border-bottom: 1px solid $gray-lightest__color;
    }
    .topmegamenu-fading {
        display: none;
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 10;
    }
    .topmegamenu {
        display: flex;

        .nav-item {
            flex-grow: 1;

            &:last-child {
                & > a {
                    background-color: $secondary__color;
                }
            }
            & > a {
                position: relative;
                @include lib-font-size(15px);
                display: block;
                color: $text__color;
                z-index: 100;
                text-align: center;
                padding: $indent__m $indent__xs;
                border-left: 1px solid $color-white;
                border-right: 1px solid $color-white;

                &:hover {
                    border-left: 1px solid $gray-lightest__color;
                    border-right: 1px solid $gray-lightest__color;
                    text-decoration: none;

                    &:after {
                        content: "";
                        width: 100%;
                        height: 1px;
                        background-color: $color-white;
                        position: absolute;
                        bottom: -1px;
                        left: 0;
                    }
                }
            }
            .dropdown {
                display: none;
                position: absolute;
                left: 0;
                width: 100%;
                top: calc(100% + 1px);
                z-index: 100;
                background: $color-white;
                padding: $indent__xl 0;

                @include max-screen($screen__xl) {
                    padding: $indent__l 0;
                }

                ul {
                    list-style: none;
                    padding: 0;
                    margin-bottom: $indent__l;

                    li {
                        a {
                            @include lib-font-size(15px);
                            display: block;
                            margin-bottom: $indent__s;
                            color: $text__color;

                            &.heading {
                                @include lib-font-size(14px);
                                color: $gray__color;
                                text-transform: uppercase;
                                padding-bottom: $indent__s;
                                border-bottom: 1px solid $gray-lightest__color;
                            }
                        }
                    }
                }
                .promotions {
                    a {
                        display: block;
                        margin-bottom: $indent__base;

                        img {
                            max-width: 100%;
                        }
                    }
                }
            }
            .has-touch &.touched,
            .no-touch &.hover {
                & > a {

                }
                .dropdown {
                    display: block;
                }
            }
        }
    }
    .nav-toggle {
        display: none;
    }
    .nav-sections {
        display: none;
    }
    .nav-sections-item-title {
        display: none;
    }
    .nav-sections-item-content {
        display: block;
    }
    .nav-sections-item-content > * {
        display: none;
    }
    .nav-sections-item-content {
        > .navigation {
            display: block;
        }
    }
}
