
//Recent blog posts: https
.blog-widget-recent{
    .post-list{
      list-style: none;
      padding: 0px;

      .post-holder{
        border-bottom: 1px solid #e8e8e8;
        position: relative;

        //image
        .post-image{
          left: 50px;
          position: absolute;
        }

        //text
        .post-content{
          padding-left: 543px;
          padding-top: 50px;
        }
      }
    }
}