.cms-affiliate-information{
  #maincontent{
    margin: 0 0 0 0 !important;
  }
}

#join-us-banner{
  text-align: center;
  max-width: 600px;
  margin:0 auto 85px;

  h1{
    font-size: 60px;
    line-height: 41px;
    margin: 0 0 30px;
  }
  h3{
    font-size: 26px;
    color: #e69470;
    margin-bottom: 20px;

    strong{
      font-size: 30px;
    }
  }

  p{
    font-size: 14px;
  }

  .action.primary{
    margin-top: 30px;
    padding: 15px 30px;
  }
}

#how-it-works-banner{
  padding: 0 94px;

  @include max-screen($screen__s) {
    padding: 0;
  }

  .how-it-works{
    &  > h3{
      text-align: center;
      margin: 55px 0 55px 0;
      font-size: 26px;
    }
    & > p {
      text-align: center;
    }
  }
  .panel{
    margin-bottom: 20px;

    .panel-head{
      text-align: center;
      background: #73a154;
      -webkit-border-top-left-radius: 5px;
      -webkit-border-top-right-radius: 5px;
      -moz-border-radius-topleft: 5px;
      -moz-border-radius-topright: 5px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      padding: 20px;
      border:1px solid #73a154;
    }

    .panel-body{
      padding: 35px 40px;
      border-left: 1px solid #d5d5d5;
      border-right: 1px solid #d5d5d5;
      border-bottom: 1px solid #d5d5d5;
      background: #fff;
      min-height: 220px;

      @include max-screen($screen__m) {
        min-height: auto;
      }

      h3{
        color: #73a154;
        font-size: 24px;
        margin: 0 0 20px;
      }

      p{
        color: #3e3d30;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 20px;
      }
    }
  }

  .want-to-know-more{
    padding-top: 85px;
    padding-bottom: 85px;

    &  > h3{
      text-align: center;
      margin: 0 0 20px 0;
      font-size: 26px;
    }
    & > p {
      text-align: center;

      .action{
        margin-top: 20px;
      }
    }
  }
}

#why-choose-us{
  padding-top: 95px;
  padding-bottom: 95px;

  h2{
    text-align: center;
    margin-bottom: 50px;
  }
  p{
    font-size: 14px;
  }
  .container{
    padding: 0 160px;

    @include max-screen($screen__s) {
      padding: 0;
    }

    .row > div{
      padding: 0 50px;
    }
  }

  .well{
    background: white;
    padding: 30px 40px;
    margin: 30px 0;

    .title{
      color: #73a154;
      margin-bottom: 10px;
      font-size: 28px;
    }
    p{
      font-size: 16px;
    }
  }
  ul{
    list-style: none;
    padding-left: 0px;

    li{
      position: relative;
      padding-left: 50px;
      font-size: 16px;
      margin-bottom: 20px;

      i{
        color: #73a154;
        font-size: 25px;
        position: absolute;
        left: 0;
        top: 2px;
      }
    }
  }
}

/*Backgrounds*/
.sky-background{
  background: url("../Magento_Cms/images/sky.jpg");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: bottom;
}
.green-background{ background: #73a154; }
.dark-green-background{ background: #3d3d30; }

#frequently-asked-questions{
  padding: 80px 0;
  margin-bottom: 80px;

  &:after{
    content:' ';
    display: block;
    clear: both;
  }
  .container{
    padding: 0 210px;

    @include max-screen($screen__s) {
      padding-left: 15px;
      padding-right: 15px;
    }

    & > div{
      display: inline-block;
      float: left;
      padding-right: 50px;
    }
  }
  h3{
    font-size: 26px;
  }
  a{
    color: #e69470;
    font-size: 14px;


    &.action.primary{
      padding: 15px 25px;
      margin-top: 14px;
      color: #3d3d30;
    }
  }


}

#tools-and-resources{
  .container{
    padding: 0 120px;

    @include max-screen($screen__s) {
      padding-right: 15px;
      padding-left: 15px;
    }

    h2{
      text-align: center;
      margin-bottom: 40px;
    }
  }

  .tools-post{
    border:1px solid #d5d5d5;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    padding: 50px 90px;

    @include max-screen($screen__s) {
      padding: 20px 20px;
    }

    .tag{
      color: #73a154;
      font-size: 13px;
      margin-bottom: 15px;
      display: block;
    }
    h4{
      font-size: 24px;
      color: #3e3d30;
      margin: 0 0 15px 0;
    }

    p{
      color: #3e3d30;
      font-size: 14px;
    }
    a{
      color: #e69470;
      font-size: 14px;
      margin-bottom: 20px;
      display: block;
    }
  }
}

#meet-some-of-our-affiliates{
  padding: 40px 0;

  h2,h3,p{
    text-align: center;
    color: #fff;
  }
  h2,h3{
    font-size: 26px;
  }

  #review-slider{
    padding: 00px 70px 0;
    margin-top: 40px;
    display: flex;
    opacity: 0;

    &.slick-slider{
      &.slick-initialized{
        display: block;
        opacity: 1;
      }
    }
    .slick-arrow {
      opacity: 0.3;
    }
    .slick-slide{
      padding: 0 10px;
      float: left;

      .review{
        background: #69934a;
        padding: 20px 20px 15px 90px;
        position: relative;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;

        p{
          text-align: left;
        }
        img{
          position: absolute;
          left: 20px;
          top: 20px;
        }
      }
    }
  }
}

#ready-to-start-earning-commission{
  padding: 80px 0;
  text-align: center;

  h3{
    color: white;
    font-size: 26px;
    margin: 0 0 30px;
  }

  .action{
    width: 188px;
    height: 55px;
    line-height: 31px;
  }
}