.navigation-shadow{
    main#maincontent:before {
        opacity: 0.8;
        content: ' ';
        display: block;
        width: 100%;
        height: 100%;
        background: black;
        top: 0;
        left: 0;
        position: fixed;
        z-index: 1;
    }
}

#main-navigation {
    border-top: 1px solid #1b215c;
    border-bottom: 1px solid #1b215c;
    background: #1b215c;
    clear: both;
    position: relative;
    z-index: 1;

    @include max-screen($screen__l) {
        display:none;
    }

    .navigation {
        display: table;
        list-style: none;
        text-align: center;
        width: 100%;
        margin-bottom: 0;
        background: transparent;
        position: initial;

        //-----------------Top Root Menu
        .top-categoies {
            display: table-cell;
            padding: 0 15px;
            height: 53px;
            margin-bottom: 0;

            & > a {
                display: block;
                text-align: center;
                height: 53px;
                line-height: 53px;
                font-size: 16px;
                cursor: pointer;
                font-weight: 700;
                font-family: 'domine', Arial;
                text-transform: uppercase;
                color:white;
            }
        }

        //-----------------Sub Dropdown Menus
        .submenu {
            background: #fff;
            display: none;
            padding: 0 0 10px 0;
            min-width: 200px;
            position: absolute;
            box-shadow: 0 9px 10px -4px rgba(0, 0, 0, 0.34);
            list-style: none;
            
            li {
                margin-bottom: 0;
        
                a {
                    display: block;
                    padding: 10px 20px;
                    text-align: left;
                    color: $gray__color;
                }
            }
        }

        //-----------------standard menu
        .standard-menu {
            padding-bottom: 0;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;

            li {
                margin-bottom: 0;

                a {
                    display: block;
                    padding: 9px 20px;
                    border-bottom: 1px solid #ddd;
                }
            }
        }

        //-----------------MegaMenu
        .megamenu {
            list-style: none;
            top: 100%;
            left: 0 !important;
            width: 100%;
            padding: 30px 0 50px;
            text-align: left;
            z-index: 3;

            .title {
                @extend h4;
                @include lib-css(padding, 0);
                @include lib-css(font-size, 15px);
                text-align: left;
                padding: 0;
                font-family: "Roboto Condensed";
                color: $primary__color;
                font-weight: 400;

                a{
                    color: $primary__color;
                    padding: 0;
                    font-weight: 400;
                }
            }

            .col-s-4{
                .nav{
                    margin: 0 -15px;

                    a{
                        width: 50%;
                        float: left;
                        padding: 5px 15px;
                    }
                }
            }
            nav {
                a {
                    padding: 5px 0;
                    font-size: 14px;
                    color: $primary__color;
                }
            }

            .widget.block.block-static-block{
                margin: 0px;
            }
        }
    }
}
