#mobile-menu{
  .switcher{
    &.switcher-currency,
    &.switcher-language{
      display: block !important;
      text-align: left;
      background: #f2f2f2;

      #switcher-currency-trigger-nav strong:before{
        font-weight: 500;
        font-size: 13px;
      }
      #switcher-language-trigger-nav strong span{
        display: inline-block;
      }


      ul.dropdown{
        background: #e5e5e5;
        width: 200px;

        li{
          a{
            background: #e5e5e5;
            padding: 10px 20px;
            text-align: left;
            font-size: 13px;
            color: $gray-darker__color;
            display: block;
            width: 100%;
            border-bottom: 1px solid #d8d8d8;
          }
        }
      }
    }
  }

  .mobile-menu-item{
    clear: both;

    .navigation{
      background: #fff;
    }

    .title,
    .navigation .level0 > .level-top,
    .navigation .level0 a,
    nav > a{
      background: #fff;
      padding: 10px 20px;
      text-align: left;
      font-size: 13px;
      color: $primary__color;
      display: block;
      width: 100%;
      border-bottom: 1px solid #d8d8d8;
    }
    .navigation .level0 a,
    .navigation .level0 > .level-top,
    nav > a{
      background: #fff;
      text-transform: initial;
    }


    .navigation .level0 > .level-top:before,
    .navigation .level0 > .level-top:after{
      background: #aaaaaa;
      content:' ';
      display: block;
      width: 12px;
      height: 12px;
      position: absolute;
      top: 17px;
      right: 10px;
    }
    .navigation .level0 > .level-top:before{
      width: 2px;
      top: 12px;
      right: 15px;
    }
    .navigation .level0 > .level-top{
      &.ui-state-active:before{
        display: none;
      }
      &:after{ height: 2px; }
    }


  }
}



