//
//  Magento\CatalogSearch
//  --------------------------------------------

//Toggle dropdown
#search-button{
    display: block;
    width: 24px;
    height: 29px;
    padding: 0;
    background-image: url(../images/search.png);
    background-repeat: no-repeat;
    margin-right: 30px;
    float: left;
    background-position: center;
    transition: width 3s, height 3s;
    cursor: pointer;

    &:before,
    &:after{
        content:'';
        width:0;
        height:0;
    }
    span{
        display: none;
    }

    &.close-icon{
        background-image: none;
        position: relative;

        &:before,
        &:after{
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: #252b63;
            border-radius: 2px;
            top: 14px;
            box-shadow: 0 0 2px 0 #ccc;
            transition-duration: 0.8s;
            transition-property: transform;
        }

        &:before{
            -webkit-transform:rotate(45deg);
            -moz-transform:rotate(45deg);
            transform:rotate(45deg);
            left:0px;
        }
        &:after{
            -webkit-transform:rotate(-45deg);
            -moz-transform:rotate(-45deg);
            transform:rotate(-45deg);
            right:0px;
        }
    }
}

#search_autocomplete{
    ul{
        list-style: none;
        background: white;
        border: 1px solid #ccc;
        padding-left: 0;

        li{
            margin: 0;
            font-size: 14px;
            padding: 5px;
            color: #6a6a6a;
            border-bottom: 1px solid #f8f8f8;
        }
    }
}
.block-search {
    display: inline-block;
    float: left;
    margin: 0;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;

    .block {
        &-title {
            display: none;
        }
        &-content {
            margin: 0 auto;
            max-width: 800px;
        }
    }
}

.minisearch {
    position: relative;

    .label {
        display: none;
    }
    .control {
        overflow: hidden;
    }
    #search {
        @include lib-input-placeholder($gray__color);
        background-color: $color-white;
        border-color: $border__color;
        height: 45px;
        width: 100%;
        box-shadow: none;
        margin: 0;
        padding-right: 65px;
        @include lib-css(border-radius, $button__border-radius);
        z-index: 3;
    }
    
    .action.search {
        @include lib-button-reset();
        position: absolute;
        right: 0;
        top: 0;
        height: 45px;
        width: 65px;
        z-index: 5;
        color: $color-white;
        background-color: $color-white;
        border: 1px solid $border__color;
        border-left: 0;
        
        span {
            @extend .abs-visually-hidden;
        }
        
        &:disabled {
            opacity: 1;
            cursor: default;
        }
        
        &:before {
            width: 24px;
            margin: 0 auto;
            height: 24px;
            content: ' ';
            display: block;
            background-image: url('../images/search.png');
          }
         
    }
}
