.product-items{
  gap: 10px;
  list-style: none;
  padding-left: 0;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  //Product Grid
  .products-grid{
    .product-item{

    }
  }
  //Product List
  .products-list{

  }
}