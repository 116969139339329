//
//  Grouped product
//  ---------------------------------------------

.grouped-wrapper {
	position: relative;

	& > ul {
        @extend .abs-reset-list;
		@extend .flex;
		flex-direction: column;
        margin-bottom: $indent__s;

		li {
			@include lib-font-size(15);
			display: none;
			width: 100%;
			order: 1;
            padding: $indent__m;
            background-color: #f9f9f9;
            border: 1px solid $form-element-input__border-color;
			border-radius: 3px;
			margin-bottom: $indent__xs;
            cursor: pointer;

			&.active {
				display: block;
				order: 0;

				p {
					&:before {
                        @include fas;
                        @include fa-icon;
						float: right;
                        content: fa-content($fa-var-chevron-down);
						color: #b4b4b4;
						margin-top: 3px;
					}
				}
			}
			&:first-child {
				&:not(.active) {
					display: none;
				}
			}
            &:hover {
                border-color: $primary__color;
            }
			p {
				display: block;
                margin: 0;
			}
            .price-box {
				@include lib-clearfix();
                margin-top: $indent__xs;

                .old-price,
				.special-price {
                    float: left;
                    clear: none;
                }
            }
            .control.qty {
                display: none;
            }
			.row-tier-price {
				margin-top: $indent__xs;
			}
		}
		&.active {
			li {
				display: block;
			}
		}
	}
	p {
		margin-bottom: $indent__base;
		
		strong {
			color: $secondary__color;
		}
	}
}
