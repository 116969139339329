//
//  Newsletter
//  ---------------------------------------------

.page-footer {
    .newsletter-bar{
        background-color: $primary__color;
        padding: 20px 0;
        margin-bottom: 50px;

        .block.newsletter{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 80px;
            gap: 26px;
            margin: 0 auto;
            flex-wrap: wrap;

           .title{
               font-size: 24px;
               margin-bottom:0;
           }
            & > *{
                gap: 26px;
                color:white;
                margin-bottom:0;
            }
            & > p{
                font-size:19px;
            }
            & > div:last-child {
                min-width: 200px;
                flex-grow: 2;
            }

            form.form.subscribe{
                #newsletter{
                    background: transparent;
                    border: 1px solid #545985;

                    &::-webkit-input-placeholder,
                    &::-moz-placeholder,
                    &:-ms-input-placeholder,
                    &:-moz-placeholder { /* Firefox 18- */
                        color: white;
                    }
                }
                button.action.subscribe.primary{
                    margin-left: 10px;
                    background-color: #1b215c;
                    border: #545985 1px solid;
                    height:44px;
                }
            }
        }
    }
    .block.newsletter {
        .title {
            margin-bottom: 20px;
        }
        .content {
            .form.subscribe {
                display: table;
                width: 100%;

                .field.newsletter {
                    display: table-cell;

                    .label {
                        @extend .abs-visually-hidden;
                    }
                }
                .actions {
                    display: table-cell;
                    width: 1%;
                    vertical-align: top;

                    .action.primary {
                        margin-left: $indent__s;
                        @extend .action.default;
                        background-color: $green-dark__color;
                        border-color: $green-dark__color;
                    }
                }
            }
        }
    }
}
