//
//  Buttons
//  ---------------------------------------------
$button__disabled__opacity                      : 0.1;

//  Font style
$button__padding                                : 12px 24px;
$button__font-weight                            : 400;
$button__font-family                            : 'Roboto Condensed', sans-serif;

$button__border-radius                          : 2px;
$button__font-size                              : 13px;
$button__padding                                :10px 15px;