

.product-item{
  transition: all 0.5s ease;
  position: relative;
  max-width: 400px;
  margin-bottom: 30px !important;
  width: 24%;
  flex-grow: 1;
  min-width: 200px;

  @include max-screen($screen__xs) {
    min-width: 150px;
  }
  .icon{
    display: flex;
    position: absolute;
    width: 50px;
    height: 50px;
    color: white;
    background: #da7d88;
    font-weight: bold;
    z-index: 11;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    text-align: center;
    flex-wrap: nowrap;
    line-height: 15px;
  }

  /* Product image*/
  .product-image-container {
    display: inline-block;
    max-width: 100%;
  }
  .product-image-wrapper {
    display: block;
    height: 0;
    overflow: hidden;
    position: relative;
  }

  .product-item-photo{
    display:block;
    text-align: center;
  }
  .product-image-photo {
    bottom: 0;
    display: block;
    left: 0;
    margin: auto;
    max-width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: auto !important;
    height: auto !important;
  }

  /* Product Name */
  .product-item-name{
      text-align: center;
      font-family: "Domine", serif;
      font-size: 16px;
      display: block;
      margin-bottom: $indent__m;
      word-wrap: break-word;
      hyphens: auto;
      overflow: hidden;
      height: 50px;
      font-weight: 600;

      @include max-screen($screen__xs) {
        font-size: 14px;
      }
      span{
        display: block;
        width:100%;
        font-size:12px;

        @include max-screen($screen__xs) {
          font-size: 11px;
        }
      }
        @include max-screen($screen__l) {
          height: 63px;
        }
      }

  /* Wishlist */
  .towishlist{
    position:absolute;
    top: 20px;
    right: 20px;
    z-index:1;
    line-height: 20px !important;

    &:before{
      color:#aaaaaa;
    }

    span{
      display: none;
    }
  }

  /* Product Price*/
  .price-box {
    padding-bottom: $indent__base;
    text-align: center;
    color: $primary__color;

    .price-container {
      white-space: nowrap;
    }
    .old-price,
    .special-price {
      .price-container {
        padding-top: 0;
      }
    }
    .save-price {
      margin: 2px 0;
    }
    .normal-price,
    .special-price {
      .price-including-tax {
        display: none;
      }
    }
  }

  /*  Product Item Body  */
  .product-item-body{
    border: 1px solid #ddd;
    border-bottom: 0px;
    padding: 20px;

    @include max-screen($screen__xs) {
      padding: 10px;
    }
  }
  /*  Product Item Footer  */
  .product-item-footer{
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    line-height: 40px;

    form{
      display: flex;
      flex-wrap: wrap;

      .control{
        width: 50%;
        display: flex;
        flex: auto;

        & > * {
          flex-basis: 100%;
        }

        .btn-qty-increase{
          border-right:0px;
        }
      }

      .action.tocart{
        border: 0;
        height: 40px;
        vertical-align: baseline;
        width: 50%;
        min-width: 100px;
        flex: auto;
        font-size: 12px;
        background: #eaf7fc;
        font-family: 'Roboto Condensed';
        color: $primary__color;
        background: $secondary__color;
      }
    }


    .btn-qty{
      cursor: pointer;
    }
    .btn-qty,
    .qty{
      height: 40px;
      border-right: 1px solid #ddd;
      border-top: 0;
      border-bottom: 0;
      border-left: 0;
      text-align: center;
      color: #747474;
    }
  }
}